body {
    margin: 0;
    background-color: black;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

/* .Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 80%; 
} */

/* .Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
} */

.customized-modal-header {
    background-color: #222222;
    color: #fff;
}

.ant-modal-header {
    background-color: #222222 !important;
    border-bottom: none !important;
}

.ant-modal-title {
    color: #fff !important;
}

.creator-earnings-table {
    background-color: #222;
    /* color: #fff; */
}

.wallet-table-container {
    background-color: #222;
    padding: 16px 0;
}

.creator-earnings-table thead th {
    background-color: #222;
    color: white;
}

.creator-earnings-table tbody td {
    background-color: #222;
    color: #ccc;
}

.creator-earnings-table tbody:hover {
    background-color: #222;
}

.creator-earnings-table tbody tr:hover {
    background-color: #222;
}

.creator-earnings-table .ant-empty-description {
    color: #ccc;
}

.creator-earnings-table .ant-table-thead {
    background-color: #222;
}

.creator-input {
    background: transparent !important;
    border-radius: 10px !important;
    height: 60px;
}

.Modal .ant-modal-body {
    padding: 24px 0;
}

button.add-address {
    background-color: transparent;
    border: 1px solid rgb(178, 50, 50);
    color: rgb(178, 50, 50);
}

button.add-address:hover {
    background-color: rgb(178, 50, 50);
    color: #fff;
}

.creator-earnings-table .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background-color: #222;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
    background: none !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background-color: #222 !important;
}

.add-address .ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
    background-color: transparent !important;
    color: rgb(178, 50, 50) !important;
}

.ant-table-cell-scrollbar:not([rowspan]) {
    box-shadow: none !important;
}

.ant-input-number-handler-wrap {
    display: none;
}

.ant-modal {
    padding-bottom: 0 !important;
}

.ant-modal-content {
    box-shadow: none !important;
}

.ant-modal {
    padding: 10px !important;
    border-radius: 8px !important;
}
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(178, 50, 50);
    border-radius: 10px;
}
