.dashboardBtns {
    border: 1px solid #D54343 !important;
    border-radius: 20px !important;
    height: 40px !important;
}

.dashboardCards {
    width: 260px;
    background-color: transparent !important;
    border: none !important;
    border-radius: 10px !important;
    overflow: hidden !important;
}

.coverIframe {
    background-color: transparent !important;
    border: none !important;
    border-radius: 10px !important;
    overflow: hidden !important;
}

@media only screen and (max-width: 600px) {
    .breakline {
        display: none;
    }

    .dashboardExploreBtns {
        display: flex;
        justify-content: space-between;
    }
}