.metamaskBtn {
  height: 50px !important;
  border-radius: 20px !important;
  border: none !important;
  color: black !important;
  min-width: 40%;
}

.cardContent {
  flex-direction: column;
  align-items: center;
  margin-top: -80px;
}

.paymentCards {
  width: 565px;
}

.tapStyle {
  margin: 15px;
}

@media only screen and (max-width: 600px) {
  .paymentCardsView {
    flex-direction: column;
  }

  .paymentCards {
    width: auto;
  }

  .tapStyle {
    margin: 5px;
  }
}
