.listingStepContainer {
  margin-top: 2rem;
}

.noteText {
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 29px;
  color: #282828;
  font-family: "Nunito Sans", sans-serif;
}

.spanText {
  font-family: "Nunito Sans", sans-serif;
  color: rgb(40, 40, 40, 0.7);
  font-weight: 400;
  font-size: 21px;
}

.contentDiv {
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.45);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 1rem;
  margin-top: 2.7rem;
}

.leftDiv {
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  gap: 10px;
  flex: 2;
}

.leftDivText {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 34px;
  color: #c73a3a;
  margin: 0;
}
.leftDivSubText {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 34px;
  color: #000;
  margin: 0;
}

.divImg {
  width: 55px;
  height: 55px;
  border-radius: 50px;
}

.rightDiv {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.numText {
  margin: 0;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 34px;

  color: #2f2f2f;
}

.ethText {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 34px;

  color: #239cd0;
  text-transform: uppercase;
}

@media (max-width: 991.98px) {
  .leftDiv {
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
  }
}

.bottomContentDiv {
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.45);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 1rem;
  margin-top: 1.5rem;
}
