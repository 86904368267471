.loginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .logoSize {
    height: 250px;
    width: 250px;
  }
  
  .formContainer {
    background-color: white;
    width: 100%;
    border-radius: 20px;
    padding: 40px;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .formContainer input {
    border: none !important;
    border-bottom: 2px solid #dcdcdc !important;
    padding-bottom: 15px !important;
    padding-top: 15px !important;
    text-align: center;
    color: #b0b0b0 !important;
    font-weight: 400 !important;
    width: 100%;
  }
  
  .formContainer input::placeholder {
    color: #b0b0b0 !important;
  }
  
  @media only screen and (max-width: 1000px) {
    .formMobView {
      flex-wrap: wrap;
    }
    .formContainer {
      margin: 0px !important;
      margin-bottom: 20px !important;
    }
  }
  

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: #000000;
  }